export const siteTheme = {
  breakpoints: {
    ns: "screen and (min-width: 30em)",
    medium: "screen and (min-width: 30em)",
    large: "screen and (min-width: 64em)",
    xl: "screen and (min-width: 94em)",
  },
  colors: {
    document: {
      background: "#ffffff",
      footerBackground: "#F6F0EF",
    },
    text: {
      standard: "#5F5F5F",
      minorLink: "#BCBCBC",
      reversed: "#ffffff",
      pullColor: "#F0534C",
    },
    buttons: {
      background: "#000000",
      color: "#ffffff",
    },
  },
  typography: {
    family: {
      standard: "Graphik",
      bold: "Graphik",
      heading: "Graphik",
    },
    sizes: {
      regular: "calc(18px + (20 - 18) * ((100vw - 320px) / (1800 - 320)))",
      h1: "calc(32px + (45 - 32) * ((100vw - 320px) / (1800 - 320)))",
      h2: "calc(28px + (40 - 28) * ((100vw - 320px) / (1800 - 320)))",
      h3: "calc(24px + (32 - 24) * ((100vw - 320px) / (1800 - 320)))",
      h4: "calc(22px + (24 - 22) * ((100vw - 320px) / (1800 - 320)))",
    },
    lineHeights: {
      standard: "1.8",
      heading: "1.5",
    },
    letterSpacing: {
      standard: "0",
      nav: "0.81px",
    },
    measures: {
      large: "55em",
      medium: "45em",
      standard: "30em",
      narrowHeading: "16em",
      heading: "22em",
    },
    hardBreak: "2rem",
  },
  padding: {
    hMobile: "1.4rem",
    hDesk: "6vw",
    vMobile: "70px",
    vDesk: "6rem",
  },
  containerWidths: {
    narrow: "1140px",
    standard: "1270px",
  },
}
