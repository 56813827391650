import React from "react"
import styled from "styled-components"
// Import Common Components
import { StandardContainer } from "../common"
import { InstagramFeed } from "./instagramFeed"
import { FooterContacts } from "./footerContacts"
import { FooterBottom } from "./footerBottom"

// Styles
const StyledFooter = styled(StandardContainer)`
  background: ${props => props.theme.colors.document.footerBackground};
`

export const Footer = () => {
  return (
    <StyledFooter>
      <InstagramFeed />
      <FooterContacts />
      <FooterBottom />
    </StyledFooter>
  )
}
