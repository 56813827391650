// Path Resolver
export const getSanityPath = ({ _type, slug }) => {
  switch (_type) {
    case "standardPage":
      return `/${slug.current}`
    case "projectPage":
      return `/projects/${slug.current}`
    case "projectPortal":
      return `/projects`
    default:
      return null
  }
}
