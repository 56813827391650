import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

// Common Components
import { FooterLogo } from "./footerLogo"
import {} from "../common"

// Styles
const StyledFooterBottom = styled.div`
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  > a {
    max-width: 250px;
  }
  > div {
    margin-top: 2rem;
    text-align: center;
  }
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
    li {
      margin-left: 0.5rem;
      padding: 0 0.3rem;
    }
  }
  p {
    font-size: 16px;
  }
  @media ${props => props.theme.breakpoints.medium} {
    flex-direction: row;
    justify-content: space-between;
    > div {
      text-align: right;
    }
    ul {
      align-items: center;
      justify-content: flex-end;
    }
  }
`

export const FooterBottom = () => {
  const { sanityFooterSettings } = useStaticQuery(graphql`
    query {
      sanityFooterSettings {
        partners {
          logo {
            asset {
              url
            }
          }
          link
        }
      }
    }
  `)
  const { partners } = sanityFooterSettings || {}
  return (
    <StyledFooterBottom>
      <FooterLogo />
      <div>
        <ul>
          {partners.map(({ logo, link }) => (
            <li>
              <a href={link} target="_blank" rel="noopener noreferrer">
                {logo && <img src={logo.asset.url} alt="" />}
              </a>
            </li>
          ))}
        </ul>
        <p>&copy; Buildstrong Construction {new Date().getFullYear()}.</p>
      </div>
    </StyledFooterBottom>
  )
}
