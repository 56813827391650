import React from "react"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"

// Import common components
import { SanityPageLink } from "../common"

// Styles
const StyledNavWrapper = styled(motion.div)`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #f6f0ef;
  z-index: 99;
  display: flex;
  align-items: center;
  padding-left: ${props => props.theme.padding.hMobile};
  padding-right: ${props => props.theme.padding.hMobile};
  top: 0;
  left: 0;
  > nav {
    display: grid;
    grid-row-gap: 1rem;
    a {
      font-size: ${props => props.theme.typography.sizes.h2};
      color: ${props => props.theme.colors.text.pullColor};
    }
  }
`

// Animation Variants

const variants = {
  open: {
    opacity: 1,
  },
  closed: {
    opacity: 0,
    transition: {
      delay: 0.5,
    },
  },
}
const navVariants = {
  open: {
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.2,
    },
  },
  closed: {
    transition: { staggerChildren: 0.1, staggerDirection: -1 },
  },
}

const linkVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      damping: 300,
    },
  },
  closed: {
    y: 20,
    opacity: 0,
    transition: {
      type: "spring",
      damping: 300,
    },
  },
}
export const MobileNav = ({ items, menuVisible, onClose }) => {
  return (
    <AnimatePresence>
      {menuVisible && (
        <StyledNavWrapper
          variants={variants}
          initial="closed"
          animate="open"
          exit="closed"
        >
          <motion.nav variants={navVariants}>
            {items.map(({ linkText, pageReference }) => (
              <motion.div variants={linkVariants}>
                <SanityPageLink
                  {...pageReference}
                  activeClassName="active"
                  partiallyActive={true}
                  onClick={onClose}
                >
                  {linkText}
                </SanityPageLink>
              </motion.div>
            ))}
          </motion.nav>
        </StyledNavWrapper>
      )}
    </AnimatePresence>
  )
}
