import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import is from "styled-is"

import BlockContent from "@sanity/block-content-to-react"

// Import Common Components
import { StyledHeading } from "./"
import { getSanityPath } from "../../utils/sanity"

// Styles
const StyledPortableText = styled.div`
  font-size: ${props => props.theme.typography.sizes.regular};
  line-height: ${props => props.theme.typography.lineHeights.standard};

  p {
    margin-bottom: 0.3em;
  }
  a {
    color: ${props => props.theme.colors.text.pullColor};
    text-decoration: underline;
  }

  ${is("measured")`
     p {
      max-width: ${props => props.theme.typography.measures.standard};
    }
    h1,h2,h3,h4{
      max-width: ${props =>
        props.headingMeasure
          ? `${props.headingMeasure}em`
          : props.theme.typography.measures.heading};
    }
  `}
`
const StyledPageLinks = styled.nav`
  padding: 1rem 0;
  > a {
    margin-right: 1rem;
  }
`
const StyledHardBreak = styled.span`
  display: block;
  height: ${props => props.theme.typography.hardBreak};
`

const BlockRenderer = props => {
  const { style = "normal" } = props.node

  if (/^h\d/.test(style)) {
    return (
      <StyledHeading as={style} size={style} pullColor measureWide={true}>
        {props.children}
      </StyledHeading>
    )
  }

  if (style === "blockquote") {
    return <blockquote>- {props.children}</blockquote>
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props)
}

const PageLinkRenderer = props => {
  const { linkText, pageReference } = props.node || {}
  const path = pageReference ? getSanityPath(pageReference) : null
  return <Link to={path}>{linkText}</Link>
}

const PageLinksRenderer = props => {
  const { links } = props.node || {}
  console.log(props)
  return links ? (
    <StyledPageLinks>
      {links.map(({ linkText, pageReference }) => {
        const path = pageReference ? getSanityPath(pageReference) : null
        return <Link to={path}>{linkText}</Link>
      })}
    </StyledPageLinks>
  ) : null
}

const HardBrekRenderer = props => {
  return <StyledHardBreak />
}

export const SanityPortableText = ({
  text,
  measured,
  headingMeasure,
  wrapped,
}) => {
  return (
    <StyledPortableText measured={measured} headingMeasure={headingMeasure}>
      <BlockContent
        blocks={text._rawText}
        serializers={{
          types: {
            block: BlockRenderer,
            pageLink: PageLinkRenderer,
            pageLinks: PageLinksRenderer,
          },
          hardBreak: HardBrekRenderer,
        }}
      />
    </StyledPortableText>
  )
}

export const SanityPageLink = ({ children, ...props }) => {
  const path = getSanityPath(props)
  return (
    <Link to={path} {...props}>
      {children}
    </Link>
  )
}
