import React from "react"
import styled from "styled-components"

import { motion } from "framer-motion"

// Styles
const StyledMenuToggle = styled(motion.svg)`
  color: ${props => props.theme.colors.text.pullColor};
  stroke: ${props => props.theme.colors.text.pullColor};
`
// Animation
const top = {
  closed: {
    rotate: 0,
    translateY: 0,
  },
  opened: {
    rotate: 45,
    translateY: 2,
  },
}

const bottom = {
  closed: {
    rotate: 0,
    translateY: 0,
  },
  opened: {
    rotate: -45,
    translateY: -2,
  },
}

export const MenuToggle = ({ isOpen, width = 30, height = 15, ...props }) => {
  const variant = isOpen ? "opened" : "closed"
  const unitHeight = 4
  const unitWidth = (unitHeight * width) / height
  return (
    <StyledMenuToggle
      viewBox={`0 0 ${unitWidth} ${unitHeight}`}
      overflow="visible"
      preserveAspectRatio="none"
      width={width}
      height={height}
      strokeWidth="2.5"
    >
      <motion.line
        x1="0"
        x2={unitWidth}
        y1="0"
        y2="0"
        variants={top}
        animate={variant}
        initial="closed"
        vectorEffect="non-scaling-stroke"
      />
      <motion.line
        x1="0"
        x2={unitWidth}
        y1="4"
        y2="4"
        variants={bottom}
        animate={variant}
        initial="closed"
        vectorEffect="non-scaling-stroke"
      />
    </StyledMenuToggle>
  )
}
