import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

// Components
import { SanityPortableText, StyledHeading } from "../common"
// Styles
const StyledFooterContact = styled.div`
  padding: ${props => props.theme.padding.hMobile} 0;
  * {
    color: ${props => props.theme.colors.text.pullColor};
  }
  article {
    display: grid;
    max-width: 1140px;
    grid-row-gap: 2rem;
    margin: 2rem auto;
    > div {
      text-align: center;
    }
  }
  @media ${props => props.theme.breakpoints.medium} {
    padding: ${props => props.theme.padding.vDesk} 0;
    article {
      margin-top: 4rem;
      grid-template-columns: 1fr 1fr;
    }
  }
`
export const FooterContacts = () => {
  const { sanityFooterSettings } = useStaticQuery(graphql`
    query {
      sanityFooterSettings {
        footerQuote
        contactDetails {
          _rawText
        }
      }
    }
  `)
  const { footerQuote, contactDetails } = sanityFooterSettings || {}
  return (
    <StyledFooterContact>
      <StyledHeading size="h2" as="h2" pullColor centerText measure>
        {footerQuote}
      </StyledHeading>
      <article>
        {contactDetails.map(({ _rawText }) => (
          <SanityPortableText text={{ _rawText: _rawText }} />
        ))}
      </article>
    </StyledFooterContact>
  )
}
