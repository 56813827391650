import React from "react"
import styled from "styled-components"
import is from "styled-is"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

// Styles
const StyledScrollReveal = styled(motion.div)`
  ${is("container")`
    padding-left: ${props => props.theme.padding.hMobile};
    padding-right: ${props => props.theme.padding.hMobile};
    
  `}
  ${is("wrappedStandard")`
    max-width: ${props => props.theme.containerWidths.standard};
    margin-left: auto;
    margin-right: auto;
  `}
  
  ${is("wrappedNarrow")`
    max-width: ${props => props.theme.containerWidths.narrow};
    margin-left: auto;
    margin-right: auto;
  `}
  @media ${props => props.theme.breakpoints.medium} {
      ${is("container")`
        padding-left: ${props => props.theme.padding.hDesk};
        padding-right: ${props => props.theme.padding.hDesk};
    
      `}
  }
`
// Animation Variants
const variants = {
  visible: { opacity: 1, y: 0 },
  initial: { opacity: 0, y: 50 },
}
export const ScrollReveal = ({ children, threshold = 0.5, ...props }) => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: threshold,
    triggerOnce: true,
  })
  return (
    <StyledScrollReveal
      ref={ref}
      animate={inView ? "visible" : "initial"}
      variants={variants}
      transition={{
        type: "spring",
        damping: 600,
      }}
      {...props}
    >
      {children}
    </StyledScrollReveal>
  )
}
