import React, { useState } from "react"
import { globalHistory } from "@reach/router"
import { Link, useStaticQuery, graphql } from "gatsby"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

import styled from "styled-components"
import is from "styled-is"

// Import Common Components
import { SanityPageLink } from "../common"
import { LogoMark, MenuToggle, MobileNav } from "./"

// Styles
const StyledHeader = styled(motion.header)`
  position: absolute;
  top: 0;
  left: 0;
  padding-left: ${props => props.theme.padding.hMobile};
  padding-right: ${props => props.theme.padding.hMobile};
  z-index: 999;
  width: 100%;
  display: flex;
  padding-top: 2rem;
  justify-content: space-between;

  > nav {
    display: none !important;
    text-align: right;
    a {
      font-size: ${props => props.theme.typography.sizes.h3};
      color: ${props => props.theme.colors.text.minorLink};
      transition: color 0.2s ease;
    }
    a.active {
      color: ${props => props.theme.colors.text.pullColor};
    }
  }
  ${is("isHome")`
    > nav a {
      color: ${props => props.theme.colors.text.pullColor};
    }
  `}
  button {
    display: block;
  }
  @media ${props => props.theme.breakpoints.medium} {
    padding-left: ${props => props.theme.padding.hDesk};
    padding-right: ${props => props.theme.padding.hDesk};
    > nav {
      display: grid !important;
      grid-row-gap: 1rem;
    }
    button {
      display: none;
    }
  }
`
const StyledHomeLink = styled(Link)`
  width: 30px;
  > .gatsby-image-wrapper {
    width: 100%;
  }
  @media ${props => props.theme.breakpoints.medium} {
    width: 55px;
  }
`

// Animation Variants
const variants = {
  visible: { opacity: 1, y: 0 },
  initial: { opacity: 0, y: "-100%" },
}

export const Header = ({ isHome }) => {
  const { sanitySiteNav } = useStaticQuery(graphql`
    query {
      sanitySiteNav {
        mainNav {
          linkText
          pageReference {
            ... on SanityProjectPage {
              _type
              _id
              slug {
                current
              }
            }
            ... on SanityStandardPage {
              _type
              _id
              slug {
                current
              }
            }
            ... on SanityProjectPortal {
              _type
            }
          }
        }
      }
    }
  `)
  const [menuVisible, setMenuVisible] = useState(false)
  const navItems = sanitySiteNav.mainNav || []
  const path = globalHistory.location.pathname
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.5,
  })
  return (
    <>
      <StyledHeader isHome={path === "/"} ref={ref}>
        <StyledHomeLink to="/">
          <LogoMark />
        </StyledHomeLink>
        <motion.nav
          animate={inView ? "visible" : "initial"}
          variants={variants}
          transition={{
            type: "spring",
            damping: 80,
          }}
        >
          {navItems.map(
            ({ linkText, pageReference }) =>
              linkText &&
              pageReference && (
                <SanityPageLink
                  {...pageReference}
                  activeClassName="active"
                  partiallyActive={true}
                >
                  {linkText}
                </SanityPageLink>
              )
          )}
        </motion.nav>
        <button onClick={() => setMenuVisible(!menuVisible)}>
          <MenuToggle isOpen={menuVisible} />
        </button>
      </StyledHeader>
      <MobileNav
        items={navItems}
        menuVisible={menuVisible}
        onClose={() => setMenuVisible(false)}
      />
    </>
  )
}
