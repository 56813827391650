import React from "react"
import styled from "styled-components"
import is from "styled-is"

import EmblaCarouselReact from "embla-carousel-react"

const StyledCarouselCanvas = styled.div`
  display: flex;
  align-items: ${props => props.alignItems};
`

const StyledStandardCarousel = styled(EmblaCarouselReact)`
  overflow: visible !important;
  position: relative;
  ${is("padHorizontal")`
    padding-left: ${props => props.theme.padding.hMobile};
    padding-right: ${props => props.theme.padding.hMobile};
  `}
  @media ${props => props.theme.breakpoints.medium} {
    ${is("padHorizontal")`
      padding-left: ${props => props.theme.padding.hDesk};
      padding-right: ${props => props.theme.padding.hDesk};
    `}
  }
`

export const StandardCarousel = ({ children, ...props }) => {
  return (
    <StyledStandardCarousel
      options={{
        loop: false,
        startIndex: 0,
        containScroll: true,
        dragFree: false,
        containScroll: "trimSnaps",
      }}
      {...props}
    >
      <StyledCarouselCanvas>{children}</StyledCarouselCanvas>
    </StyledStandardCarousel>
  )
}
