import React from "react"
import styled from "styled-components"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const StyledFooterLogo = styled(Link)`
  width: 280px;
  display: block;
`

export const FooterLogo = () => {
  const { footerLogo } = useStaticQuery(graphql`
    query {
      footerLogo: file(relativePath: { eq: "footer-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <StyledFooterLogo to="/">
      <Img fluid={footerLogo.childImageSharp.fluid} />
    </StyledFooterLogo>
  )
}
