import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

export const FullSizeBackgroundImage = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: -1;
`
