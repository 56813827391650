import styled from "styled-components"
import is from "styled-is"

export const StandardContainer = styled.div`
  padding-left: ${props => props.theme.padding.hMobile};
  padding-right: ${props => props.theme.padding.hMobile};
  
  ${is("wrappedStandard")`
    max-width: ${props => props.theme.containerWidths.standard};
    margin-left: auto;
    margin-right: auto;
  `}
  
  ${is("wrappedNarrow")`
    max-width: ${props => props.theme.containerWidths.narrow};
    margin-left: auto;
    margin-right: auto;
  `}
  
  @media ${props => props.theme.breakpoints.medium} {
    padding-left: ${props => props.theme.padding.hDesk};
    padding-right: ${props => props.theme.padding.hDesk};
  }
`
export const StandardGridContainer = styled.div`
  --standard-grid-spacer: ${props => props.theme.padding.hMobile};
  display: grid;
  grid-template-columns: var(--standard-grid-spacer) repeat(12, 1fr) var(
      --standard-grid-spacer
    );
  @media ${props => props.theme.breakpoints.medium} {
    --standard-grid-spacer: ${props => props.theme.padding.hDesk};
  }
`
