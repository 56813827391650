import styled from "styled-components"
import { Link } from "gatsby"
import is, { match } from "styled-is"
import { motion } from "framer-motion"

// Headings
export const StyledHeading = styled(motion.h2)`
  font-family: ${props => props.theme.typography.family.heading};
  /* Is pull color */
  ${is("pullColor")`
    color: ${props => props.theme.colors.text.pullColor};
  `}
  ${is("reversed")`
    color: ${props => props.theme.colors.text.reversed};
  `}
  /* Size Modifiers */
  ${match("size", "h1")`
    font-size: ${props => props.theme.typography.sizes.h1};
    line-height: ${props => props.theme.typography.lineHeights.heading};
  `}
  ${match("size", "h2")`
    font-size: ${props => props.theme.typography.sizes.h2};
    line-height: ${props => props.theme.typography.lineHeights.heading};
  `}
  ${match("size", "h3")`
    font-size: ${props => props.theme.typography.sizes.h3};
    line-height: ${props => props.theme.typography.lineHeights.heading};
  `}
  ${match("size", "h4")`
    font-size: ${props => props.theme.typography.sizes.h4};
  `}
  /* Width Modifiers */
  ${is("measure")`
    max-width: ${props => props.theme.typography.measures.heading};
  `}
  ${is("measureNarrow")`
    max-width: ${props => props.theme.typography.measures.narrowHeading};
  `}
  ${is("measureWide")`
    max-width: ${props => props.theme.typography.measures.standard};
  `}
  /* Alignment Modifiers */
  ${is("centerText")`
    text-align: center;
  `}
   ${is("measure", "centerText")`
      margin-left: auto;
      margin-right: auto;
  `}
   ${is("measureWide", "centerText")`
      margin-left: auto;
      margin-right: auto;
  `}
`

// Link Styles
export const StyledTextLink = styled(Link)`
  text-decoration: underline;
  /* Color Modifiers */
  ${is("pullColor")`
    color:${props => props.theme.colors.text.pullColor};
  `}
  @media ${props => props.theme.breakpoints.medium} {
    /* Size Modifiers */
    ${match("size", "L")`
        font-size: 24px;
    `}
  }
  
`
