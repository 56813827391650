import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import { StandardCarousel, StyledHeading } from "../common"
import InstaIcon from "../../svg/insta.svg"
import FaceyIcon from "../../svg/facebook.svg"

const StyledInstaCarouselWrapper = styled.div`
  padding: 2rem 0;
  > header {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ul {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    li {
      margin-left: 1rem;
    }
    svg {
      width: 30px;
      display: block;
    }
  }
`

const StyledInstaCarousel = styled(StandardCarousel)`
  overflow: hidden !important;
`
const StyledInstaCarouselItem = styled.a`
  flex: 0 0 auto;
  user-select: none;
  box-sizing: content-box;
  position: relative;
  padding: 0 1rem;
`

export const InstagramFeed = () => {
  const { sanityFooterSettings } = useStaticQuery(graphql`
    query {
      sanityFooterSettings {
        instaLink
        facebookLink
      }
    }
  `)
  const { instaLink, facebookLink } = sanityFooterSettings || {}

  return (
    <StyledInstaCarouselWrapper>
      <header>
        <StyledHeading as="h3" size="h3" pullColor>
          Follow us
        </StyledHeading>
        <ul>
          {instaLink && (
            <li>
              <a href={instaLink} target="_blank" rel="noopener noreferrer">
                <InstaIcon />
              </a>
            </li>
          )}
          {facebookLink && (
            <li>
              <a href={facebookLink} target="_blank" rel="noopener noreferrer">
                <FaceyIcon />
              </a>
            </li>
          )}
        </ul>
      </header>
      {/* <StyledInstaCarousel>
          {allInstaNode.nodes.map(({ localFile }) => (
            <StyledInstaCarouselItem
              href={`https://www.instagram.com/buildstrong_nz`}
              target="_blank"
            >
              <Img fixed={localFile.childImageSharp.fixed} />
            </StyledInstaCarouselItem>
          ))}
        </StyledInstaCarousel> */}
    </StyledInstaCarouselWrapper>
  )
}
