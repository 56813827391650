/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { globalHistory } from "@reach/router"
import { ThemeProvider } from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
// Styles
import "../css/fonts.css"
import { siteTheme } from "../themes/theme.js"
import { GlobalStyles } from "../css/globalStyles"
// Components
import { Header } from "../components/navigation"
import { Footer } from "../components/footer"

const Layout = ({ children }) => {
  const path = globalHistory.location.pathname
  return (
    <>
      <ThemeProvider theme={siteTheme}>
        <GlobalStyles />
        <Header />
        <AnimatePresence exitBeforeEnter>
          <motion.main
            key={path}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              ease: "easeInOut",
              duration: 0.5,
            }}
          >
            {children}
            <Footer />
          </motion.main>
        </AnimatePresence>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
